(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("@dokibo/react-firebase-helpers", ["React"], factory);
	else if(typeof exports === 'object')
		exports["@dokibo/react-firebase-helpers"] = factory(require("react"));
	else
		root["@dokibo/react-firebase-helpers"] = factory(root["React"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__798__) {
return 